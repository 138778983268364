@import "./v";

@media only screen and (max-width: 1480px) {
	.container {
		max-width: 1200px;
	}
}

@media only screen and (max-width: 1280px) {
	.container {
		max-width: 1080px;
	}
	header {
		.row {
			nav {
				ul {
					gap: 48px;
				}
			}
		}
	}
	.hero {
		.col {
			.txt {
				max-width: 480px;
			}
		}
	}
	.introduction,
	.features,
	.how-it-works,
	.faqs {
		padding: 80px 0;
	}
	.contact {
		.row {
			.right {
				padding: 80px;
			}
		}
	}
}

@media only screen and (max-width: 1120px) {
	.container {
		max-width: 860px;
	}
	header {
		.container {
			max-width: 100%;
			padding: 0 32px;
		}
		.row {
			nav {
				ul {
					gap: 40px;
				}
			}
		}
	}
	.hero {
		.col {
			height: auto;
			padding: 180px 0 80px 0;
			.txt {
				max-width: 412px;
				.title {
					font-size: 42px;
				}
				.btns {
					margin-top: 32px;
				}
			}
		}
		svg.top,
		svg.bottom {
			height: 33.33%;
		}
	}
	.introduction {
		.row {
			gap: 80px;
			.col {
				height: auto;
			}
			.left {
				img {
					height: auto;
					width: 100%;
				}
			}
			.right {
				.txt {
					.btns {
						margin-top: 32px;
					}
				}
			}
		}
	}
	.features {
		.row {
			display: block;
			.left,
			.right {
				width: 100%;
			}
			.left {
				margin-bottom: 40px;
				button {
					margin-top: 32px;
				}
			}
		}
	}
	.how-it-works {
		.all {
			max-width: 532px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(auto-fill, auto);
			grid-row-gap: 12px;
			grid-column-gap: 12px;
		}
	}
	.contact {
		.row {
			.left {
				width: 33.33%;
			}
			.right {
				width: 66.66%;
			}
		}
	}
	#common-page {
		.landing {
			padding: 132px 0 60px 0;
		}
	}
}

@media only screen and (max-width: 892px) {
	.container {
		max-width: 100%;
		padding-left: 16px;
		padding-right: 16px;
	}
	header,
	header.fixed {
		background-color: white;
		padding: 12px 0 12px 0;
		position: relative;
		.row {
			.logo {
				svg {
					height: 32px;
				}
			}
		}
	}
	header {
		.row {
			align-items: center;
			justify-content: center;
			.menu-btn {
				display: block;
			}
			nav {
				display: none;
			}
			nav.open {
				display: block;
				position: absolute;
				background-color: #191a22;
				width: 268px;
				left: 0px;
				top: 56px;
				height: 100vh;
				overflow-y: scroll;
				padding: 30px 0 280px 0;
				z-index: 9999;
				@include vendorPrefix(
					"box-shadow",
					"0px 6px 20px rgba(0,0,0,.16)"
				);
				@include vendorPrefix("transition-timing-function", "ease-in");
				@include vendorPrefix("animation", "slide-from-left 0.4s");
				@include vendorPrefix("animation-fill-mode", "both");
				ul {
					display: block;
					li {
						border-bottom: solid 1px rgba(255, 255, 255, 0.04);
						margin: 0;
						a {
							padding: 0 20px;
							line-height: 48px;
							height: 48px;
							color: white;
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.hero .col,
	.introduction,
	.features,
	.how-it-works,
	.faqs {
		padding: 60px 0;
	}
	.hero {
		.col {
			.row {
				max-width: 532px;
				margin: 0 auto;
				display: block;
				.left,
				.right {
					width: 100%;
				}
				.left {
					.txt {
						max-width: 100%;
					}
				}
				.right {
					display: none;
				}
			}
		}
	}
	.introduction {
		.row {
			display: block;
			.left,
			.right {
				width: 100%;
			}
			.left {
				margin-bottom: 32px;
				img {
					width: 224px;
					margin: 0 auto;
					display: block;
				}
			}
		}
	}
	.features {
		.row {
			.left {
				max-width: 532px;
				margin: 0 auto;
				text-align: center;
				margin-bottom: 40px;
				hr {
					margin: 0 auto;
				}
			}
		}
	}
	.how-it-works {
		.row {
			.all {
				margin-top: 40px;
				margin-bottom: 40px;
			}
		}
		svg.svg-top,
		svg.svg-bottom {
			display: none;
		}
	}
	.contact {
		.row {
			display: block;
			.left,
			.right {
				width: 100%;
			}
			.right {
				padding: 60px 16px;
				.txt {
					max-width: 100%;
				}
			}
		}
	}
	#common-page {
		.landing {
			padding: 60px 0 30px 0;
			svg.top,
			svg.bottom {
				display: none;
			}
		}
		.content-area {
			padding: 30px 0 60px 0;
		}
	}
}

@media only screen and (max-width: 564px) {
	.features {
		.all {
			display: block;
			.one {
				margin-bottom: 8px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 420px) {
	.how-it-works {
		.all {
			display: block;
			.one {
				margin-bottom: 8px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	// ====== Fonts ====== //

	.hero {
		.col {
			.txt {
				.title {
					font-size: 32px;
				}
			}
		}
	}
	h1 {
		font-size: 26px;
	}
	h2 {
		font-size: 24px;
	}
	h4 {
		font-size: 20px;
	}
	button {
		padding: 0 16px;
		span {
			gap: 4px;
		}
	}
}
