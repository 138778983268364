@import "./v";

// ====== Common Pages ====== //

hr.sep {
	outline: none;
	border: none;
	height: 4px;
	width: 32px;
	background-color: $colorPrimary;
}

.hero {
	position: relative;
	background-color: white;
	@include vendorPrefix("box-shadow", "inset 0 0 80px rgba(0, 0, 0, 0.08)");
	.col {
		height: 100vh;
		position: relative;
		z-index: 3;
		.row {
			max-width: 1280px;
			margin: 0 auto;
		}
		.txt {
			max-width: 532px;
			.title {
				font-size: 68px;
			}
			.btns {
				margin-top: 48px;
				display: flex;
				gap: 8px;
			}
		}
	}
	svg.top,
	svg.bottom {
		position: absolute;
		height: 40%;
		width: auto;
		display: block;
	}
	svg.top {
		top: 0;
		right: 0;
	}
	svg.bottom {
		bottom: 0;
		left: 0;
	}
}

.introduction {
	padding: 120px 0;
	.row {
		max-width: 1080px;
		margin: 0 auto;
		.col {
			height: 720px;
		}
		.left {
			img {
				height: 100%;
				width: auto;
				display: block;
			}
		}
		.right {
			.txt {
				max-width: 532px;
				.btns {
					margin-top: 48px;
					display: flex;
					gap: 8px;
					img {
						height: 52px;
						width: auto;
						display: block;
					}
				}
			}
		}
	}
}

.features {
	padding: 120px 0;
	background-color: #f3f3fa;
	.row {
		gap: 120px;
	}
	.left {
		button {
			margin-top: 48px;
		}
	}
	.all {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-row-gap: 20px;
		grid-column-gap: 20px;
		.one {
			background-color: white;
			@include vendorPrefix("border-radius", "10px");
			@include vendorPrefix("box-shadow", "0 3px 6px rgba(0,0,0,0.012)");
			display: flex;
			padding: 20px;
			gap: 12px;
			.icon {
				width: 40px;
				svg {
					height: auto;
					width: 100%;
					display: block;
					color: $colorPrimary;
				}
			}
			.t {
				flex: 1;
				p {
					color: #373737;
					font-size: 14px;
					margin-top: 5px;
				}
			}
		}
	}
}

.how-it-works {
	padding: 120px 0;
	.row {
		position: relative;
		z-index: 3;
	}
	.top {
		max-width: 532px;
	}
	.all {
		margin-top: 60px;
		margin-bottom: 80px;
		max-width: 1080px;
		display: flex;
		gap: 40px;
		.one {
			width: 100%;
			padding: 40px 20px 30px 20px;
			background-color: #fff;
			@include vendorPrefix("border-radius", "10px");
			@include vendorPrefix("box-shadow", "0 0px 20px rgba(0,0,0,0.08)");
			.icon {
				height: 80px;
				width: 80px;
				background-color: $colorPrimary;
				background-color: white;
				background-color: #f3f3fa;
				padding: 16px;
				margin-bottom: 20px;
				svg {
					width: 100%;
					height: auto;
					display: block;
					color: $colorPrimary;
				}
			}
			.t {
				hr {
					width: 16px;
					margin: 12px auto;
				}
				p {
					color: #666;
					font-size: 14px;
				}
			}
		}
	}
	svg.svg-top,
	svg.svg-bottom {
		position: absolute;
		z-index: -1;
		height: 50%;
		width: auto;
		display: block;
		@include opacity(0.32, 32);
	}
	svg.svg-top {
		top: 0;
		right: 0px;
	}
	svg.svg-bottom {
		bottom: 0;
		left: 0;
	}
}

.faqs {
	padding: 120px 0;
	@include vendorPrefix("box-shadow", "inset 0 0 80px rgba(0, 0, 0, 0.08)");
	.top {
		max-width: 532px;
	}
	.questions {
		margin-top: 60px;
		max-width: 860px;
		.one {
			.question {
				cursor: pointer;
				padding: 24px 10px 24px 10px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px;
				border-color: #d8d8d8;
				._t {
					flex: 1;
					line-height: 1;
				}
				._t-open {
					color: $colorPrimary;
				}
				._icon {
					height: 7.5px;
					width: 12px;
					@include vendorPrefix("transition", "all 0.2s");
					path {
						fill: $colorPrimary;
					}
				}
				._icon-open {
					@include vendorPrefix("transform", "rotate(180deg)");
				}
			}
			.question-open {
				color: $colorPrimary;
			}
			.answer {
				padding: 15px 20px;
				border-bottom: solid 1px;
				border-color: #d8d8d8;
			}
			&:first-child {
				.question {
					border-top: solid 1px;
					border-color: #d8d8d8;
				}
			}
		}
	}
}

.contact {
	.row {
		.left {
			img {
				width: 100%;
				height: auto;
				display: none;
			}
		}
		.right {
			padding: 120px 80px;
			.txt {
				max-width: 532px;
			}
		}
	}
}

#common-page {
	.landing {
		position: relative;
		background-color: #f2f6fa;
		@include vendorPrefix(
			"box-shadow",
			"inset 0 0 80px rgba(0, 0, 0, 0.08)"
		);
		padding: 168px 0 102px 0;
		svg.top,
		svg.bottom {
			position: absolute;
			height: 280px;
			width: auto;
			display: block;
		}
		svg.top {
			top: 0;
			right: 0;
		}
		svg.bottom {
			bottom: 0;
			left: 0;
		}
		.txt {
			position: relative;
			z-index: 3;
		}
	}
	.content-area {
		padding: 80px 0;
		color: $colorLight;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			margin: 0.33em 0;
		}
		ul {
			li {
				padding-left: 20px;
				list-style: none;
				position: relative;
				&:after {
					position: absolute;
					content: "";
					height: 6px;
					width: 6px;
					background-color: $colorLight;
					top: 9px;
					left: 0;
					@include vendorPrefix("border-radius", "100%");
				}
			}
		}
	}
}
