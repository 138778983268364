@import "./v";

* {
	margin: 0;
	padding: 0;
	@include vendorPrefix("box-sizing", "border-box");
}
html {
	scroll-behavior: smooth;
}
body {
	height: auto;
	overflow: auto;
	position: relative;
	background: white;
	color: #17181f;
	font-family: "Nunito Sans", sans-serif;
}
h1 {
	font-size: 36px;
}
h2 {
	font-size: 32px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 16px;
}
p {
	font-size: 16px;
	line-height: 1.5;
}
p.small {
	font-size: 12px;
}
a {
	color: inherit;
	text-decoration: none;
}
.container {
	max-width: 1080px;
	max-width: 1440px;
	margin: 0 auto;
	// background-color: rgba(255, 0, 0, 0.25);
}
.f-w-200 {
	font-weight: 200;
}
.f-w-300 {
	font-weight: 300;
}
.f-w-400 {
	font-weight: 400;
}
.f-w-500 {
	font-weight: 500;
}
.f-w-600 {
	font-weight: 600;
}
.f-w-700 {
	font-weight: 700;
}
.f-w-800 {
	font-weight: 800;
}
.f-w-900 {
	font-weight: 900;
}
.pad-20 {
	padding: 20px;
}
.cover-img {
	background: url(/images/icons/default.png) no-repeat center center;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
}
.b-r-5 {
	@include vendorPrefix("border-radius", "5px");
}
.b-r-8 {
	@include vendorPrefix("border-radius", "8px");
}
.b-r-10 {
	@include vendorPrefix("border-radius", "10px");
}
.b-r-20 {
	@include vendorPrefix("border-radius", "20px");
}
.b-r-100 {
	@include vendorPrefix("border-radius", "100%");
}
.b {
	@include vendorPrefix("border", " solid 1px");
}
.flex {
	display: flex;
}
.display-block {
	display: block;
}
.center-center {
	justify-content: center;
	align-items: center;
}
.center-left {
	justify-content: left;
	align-items: center;
}
.top-left {
	justify-content: flex-start;
	align-items: flex-start;
}
.center-right {
	justify-content: right;
	align-items: center;
}
.bottom-left {
	justify-content: flex-start;
	align-items: flex-end;
}
.bottom-right {
	justify-content: flex-end;
	align-items: flex-end;
}
.flex-1 {
	flex: 1;
}
.col-1-5 {
	width: 20%;
}
.col-1-4 {
	width: 25%;
}
.col-3-10 {
	width: 30%;
}
.col-1-3 {
	width: 33.33%;
}
.col-2-5 {
	width: 40%;
}
.col-1-2 {
	width: 50%;
}
.col-3-5 {
	width: 60%;
}
.col-2-3 {
	width: 66.66%;
}
.col-7-10 {
	width: 70%;
}
.col-3-4 {
	width: 75%;
}
.col-4-5 {
	width: 80%;
}
.col-1-1 {
	width: 100%;
}
.width-auto {
	width: auto;
}
.height-auto {
	height: auto;
}
.pos-rel {
	position: relative;
}
.of-hid {
	overflow: hidden;
}
.of-vis {
	overflow: visible;
}
.t-t-u {
	text-transform: uppercase;
}
.t-a-c {
	text-align: center;
}
.t-a-r {
	text-align: right;
}
.t-a-l {
	text-align: left;
}
.t-d-ul {
	text-decoration: underline;
}
.l-h-1 {
	line-height: 1;
}
.l-h-1-2 {
	line-height: 1.2;
}
.l-h-1-5 {
	line-height: 1.5;
}
.l-h-2 {
	line-height: 2;
}
.l-s-n {
	list-style: none;
}
.cursor-pointer {
	cursor: pointer;
}
.color-white {
	color: white;
}
.color-primary {
	color: $colorPrimary;
}
.color-secondary {
	color: $colorSecondary;
}
.color-light {
	color: $colorLight;
}
.mar-0-a {
	margin: 0 auto;
}
.mar-l-a {
	margin-left: auto;
}
.mar-10-0 {
	margin: 10px 0;
}
.mar-20-0 {
	margin: 20px 0;
}
.transition {
	@include vendorPrefix("transition", "all 0.2s");
}
.hover-color-secondary {
	&:hover {
		color: $colorSecondary;
	}
}
.hover-color-primary {
	&:hover {
		color: $colorPrimary;
	}
}
.hover-color-white {
	&:hover {
		color: white;
	}
}
.bg-color-primary {
	background-color: $colorPrimary;
}
.bg-color-secondary {
	background-color: $colorSecondary;
}
.bg-color-tertiary {
	background-color: $colorTertiary;
}
.bg-color-faded {
	background-color: #fafafb;
}
.font-size-14 {
	font-size: 14px;
}
.font-size-12 {
	font-size: 12px;
}
.color-faded {
	color: #8f8f8f;
}

// ====== Form Fields ====== //

input,
select,
textarea,
button {
	border: none;
	outline: none;
	resize: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	-ms-appearance: none;
	appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner,
select::-moz-focus-inner,
textarea::-moz-focus-inner {
	outline: none !important;
}
select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #ffffff;
}
textarea {
	-webkit-rtl-ordering: logical;
	-moz-rtl-ordering: logical;
	-o-rtl-ordering: logical;
	-ms-rtl-ordering: logical;
	-rtl-ordering: logical;
	-webkit-user-select: text;
	-moz-user-select: text;
	-o-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
textarea::-ms-expand {
	display: none;
}
::-webkit-input-placeholder {
	color: #8f8f8f;
}
::-moz-placeholder {
	color: #8f8f8f;
}
::-o-placeholder {
	color: #8f8f8f;
}
:-ms-input-placeholder {
	color: #8f8f8f;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 100px #ffffff inset !important;
	-webkit-text-fill-color: #444 !important;
}
input,
select,
textarea,
.file-input-holder,
.ui-like-input {
	padding: 0 16px;
	line-height: 44px;
	height: 44px;
	font-size: 14px;
	@include vendorPrefix("border-radius", "4px");
	width: 100%;
	display: block;
	@include vendorPrefix("transition", "all 0.2s");
	border: solid 1px;
	color: #373737;
	border-color: #f3f4f6ff;
	background-color: #f3f4f6ff;
	font-family: "Nunito Sans", sans-serif;
	font-weight: 400;
	overflow: hidden;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

select {
	background: #f3f4f6ff url("/images/icons/select.svg") right center no-repeat;
	cursor: pointer;
}

input[type="checkbox"],
input[type="radio"] {
	height: 24px;
	width: 24px;
	padding: 0;
	position: relative;
	cursor: pointer;
	@include vendorPrefix("transition", "none");
}
input[type="checkbox"] {
	@include vendorPrefix("border-radius", "4px");
}
input[type="checkbox"],
input[type="checkbox"]:focus,
input[type="radio"],
input[type="radio"]:focus {
	border-color: $colorPrimary;
}
input[type="checkbox"]:checked {
	border-color: $colorPrimary;
	background-color: $colorPrimary;
}
input[type="checkbox"]:checked:before,
input[type="checkbox"]:checked:after,
input[type="radio"]:checked:before,
input[type="radio"]:checked:after {
	position: absolute;
	content: "";
}
input[type="checkbox"]:checked:before,
input[type="checkbox"]:checked:after {
	@include vendorPrefix("transform-origin", "top left");
	@include vendorPrefix(
		"transform",
		"rotate(-45deg) translateY(13px) translateX(-6px)"
	);
}
input[type="checkbox"]:checked:before {
	height: 6px;
	width: 12px;
	left: 0;
	top: -2px;
	background: white;
}
input[type="checkbox"]:checked:after {
	height: 6px;
	width: 12px;
	left: 0px;
	top: -4px;
	background: $colorPrimary;
}
input[type="radio"],
input[type="radio"]:checked:before,
input[type="radio"]:checked:after {
	@include vendorPrefix("border-radius", "100%");
}
input[type="radio"]:checked {
	border-color: $colorPrimary;
}
input[type="radio"]:checked:before {
	height: 18px;
	width: 18px;
	top: 2px;
	left: 2px;
	background: $colorSecondary;
}
input[type="radio"]:checked:after {
	height: 14px;
	width: 14px;
	top: 4px;
	left: 4px;
	background: $colorPrimary;
}
.file-input-holder {
	cursor: pointer;
	input[type="file"] {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
		@include opacity0();
	}
	svg {
		position: absolute;
		height: 16px;
		width: auto;
		display: block;
		top: 16px;
		right: 12px;
		path {
			fill: $colorPrimary;
		}
	}
}
textarea {
	height: 180px;
	padding: 16px;
	line-height: 16px;
	overflow: scroll;
}
input.error,
select.error,
textarea.error {
	border-color: red;
}
p.error-messages {
	font-size: 11px;
	padding: 5px 0;
	line-height: 1.2;
	color: red;
}

p.success-messages {
	font-size: 11px;
	padding: 5px 0;
	line-height: 1.2;
	color: green;
}

button {
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0 22px;
	height: 44px;
	line-height: 44px;
	font-size: 16px;
	font-family: "Nunito Sans", sans-serif;
	font-weight: 400;
	cursor: pointer;
	@include vendorPrefix("transition", "all 0.2s");
	@include vendorPrefix("border-radius", "6px");
	background-color: $colorPrimary;
	color: #fff;
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		img {
			height: 16px;
		}
		svg {
			height: 20px;
			width: auto;
			path {
				fill: white;
				@include vendorPrefix("transition", "all 0.2s");
			}
		}
	}
	&:hover {
		background-color: $colorSecondary;
		color: $colorPrimary;
		span {
			svg {
				path {
					fill: $colorPrimary;
				}
			}
		}
	}
	&:disabled {
		background-color: #aaa;
		color: #cfcfcf;
		cursor: not-allowed;
	}
}

button.light {
	background-color: $colorSecondary;
	color: $colorPrimary;
	svg {
		path {
			fill: $colorPrimary;
		}
	}
	&:hover {
		background-color: $colorPrimary;
		color: white;
		svg {
			path {
				fill: white;
			}
		}
	}
}

// ====== Header ====== //

header {
	width: 100%;
	top: 0;
	left: 0;
	padding: 40px 0 20px 0;
	position: fixed;
	z-index: 99;
	.row {
		width: 100%;
		.menu-btn {
			position: absolute;
			left: 8px;
			top: 16px;
			height: 22px;
			width: auto;
			cursor: pointer;
			display: none;
			z-index: 999;
			span {
				height: 4px;
				width: 28px;
				background-color: $colorLight;
				display: block;
				@include vendorPrefix("transition", "all 0.4s");
				&:nth-child(2) {
					margin-top: 5px;
				}
				&:nth-child(3) {
					margin-top: 5px;
				}
			}
		}
		.menu-btn-open {
			span {
				background: $colorPrimary;
				&:nth-child(2) {
					@include opacity0();
				}
				&:nth-child(1) {
					@include vendorPrefix(
						"transform",
						"rotate(45deg) translateY(7px) translateX(6px)"
					);
				}
				&:nth-child(3) {
					@include vendorPrefix(
						"transform",
						"rotate(-45deg) translateY(-7px) translateX(6px)"
					);
				}
			}
		}
		.logo {
			svg {
				height: 36px;
				width: auto;
				display: block;
			}
		}
		nav {
			margin-left: auto;
			display: flex;
			ul {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 86px;
				li {
					list-style: none;
					a {
						display: block;
						font-size: 16px;
						line-height: 40px;
						font-weight: 600;
						color: $colorLight;
						position: relative;
						@include vendorPrefix("transition", "all 0.2s");
						&:hover {
							color: $colorPrimary;
						}
					}
					a.active {
						color: $colorPrimary;
					}
				}
			}
		}
	}
}

header.fixed {
	padding: 20px 0 20px 0;
	background-color: white;
	@include vendorPrefix("box-shadow", "0 0 4px rgba(0,0,0,0.12)");
	.row {
		.logo {
			svg {
				height: 30px;
			}
		}
	}
}

footer {
	background-color: $colorPrimary;
	padding: 80px 0 60px 0;
	ul.links {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 40px;
		margin: 20px 0 10px 0;
		li {
			position: relative;
			a {
				font-size: 13px;
				color: white;
				&:hover {
					color: rgba(255, 255, 255, 0.68);
				}
			}
			&:after {
				position: absolute;
				content: "";
				height: 12px;
				width: 1px;
				background-color: rgba(255, 255, 255, 0.32);
				right: -20px;
				top: 6px;
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}
	svg.logo {
		height: 32px;
		width: auto;
		margin: 0 auto;
		display: block;
		path {
			fill: white;
		}
	}
}

// =================================
// ========= Animations
// =================================

// ====== Slide From Left

@-webkit-keyframes slide-from-left {
	0% {
		-webkit-transform: translateX(-300px);
	}
	100% {
		-webkit-transform: translateX(0px);
	}
}
@-moz-keyframes slide-from-left {
	0% {
		-moz-transform: translateX(-300px);
	}
	100% {
		-moz-transform: translateX(0px);
	}
}
@-o-keyframes slide-from-left {
	0% {
		-o-transform: translateX(-300px);
	}
	100% {
		-o-transform: translateX(0px);
	}
}
@-ms-keyframes slide-from-left {
	0% {
		-ms-transform: translateX(-300px);
	}
	100% {
		-ms-transform: translateX(0px);
	}
}
@keyframes slide-from-left {
	0% {
		transform: translateX(-300px);
	}
	100% {
		transform: translateX(0px);
	}
}
